export const socialIconList = [
  {
    name: 'twitter',
    prefix: 'fab', // fab prefix for brands, fas for solid icons
    link: 'https://twitter.com/krharsh17',
  },
  {
    name: 'github',
    prefix: 'fab',
    link: 'https://github.com/krharsh17',
  },
  {
    name: 'linkedin',
    prefix: 'fab',
    link: 'https://linkedin.com/in/krharsh17',
  },
  // Add further social links with the icon of choice and link here
  // check https://fontawesome.com/icons?d=gallery&s=brands for other brand icons
];
